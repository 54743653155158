import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';

export const Divider = styled(LinearProgress)`
  border-radius: 5px;
  background-color: #cba046 !important;

  .MuiLinearProgress-bar {
    background-color: #151e26;
  }
`;

export const MediaBox = styled.div<{ index?: number }>`
  padding: 0;

  @media (min-width: 768px) {
    padding: ${(props) => (props.index === 0 ? '20px' : 0)};
  }
`;

export const ImageStyled = styled.img`
  width: 20px;
  margin-right: 12px;
`;

export const SubHeader = styled(Typography)`
  margin-bottom: 14px !important;
`;

export const Header = styled(Typography)`
  margin-bottom: 14px !important;
  align-items: start;
  display: flex;
`;
