import { FC } from "react";

type Props = {
  style?: any;
};

export const ArrowIcon: FC<Props> = ({ style }) => (
  <svg
    width="37"
    height="47"
    viewBox="0 0 37 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M35.3849 13.8724C35.1624 13.8425 34.9314 13.8932 34.7052 14.0217C34.4791 14.1501 34.2621 14.3537 34.0668 14.6208C33.8715 14.8879 33.7017 15.2133 33.5671 15.5783C33.4325 15.9433 33.3357 16.3408 33.2824 16.7479L30.8104 35.5405L26.5662 24.5768C23.899 17.687 21.2881 10.9426 17.7121 6.35107C14.0145 1.60986 9.35091 -0.769381 2.69074 0.716203C2.23794 0.841018 1.80136 1.27173 1.47367 1.91692C1.14597 2.56212 0.952954 3.37101 0.935577 4.17192C0.918199 4.97282 1.07783 5.70271 1.38059 6.20666C1.68335 6.71061 2.10541 6.94896 2.55719 6.87112C8.43971 5.559 12.3186 7.64759 15.3922 11.5983C18.5483 15.6446 20.9184 21.7203 23.6746 28.8401L27.8407 39.6019L17.5688 38.2459C17.3465 38.2163 17.1158 38.267 16.8899 38.3953C16.6639 38.5235 16.4471 38.7268 16.252 38.9934C16.0568 39.26 15.887 39.5848 15.7523 39.9491C15.6176 40.3135 15.5206 40.7104 15.4669 41.1171C15.4132 41.5238 15.4038 41.9323 15.4393 42.3194C15.4747 42.7064 15.5543 43.0645 15.6736 43.373C15.7928 43.6815 15.9493 43.9345 16.1341 44.1175C16.319 44.3005 16.5285 44.41 16.7509 44.4396L31.1104 46.3353C31.333 46.3652 31.5639 46.3145 31.7901 46.1861C32.0163 46.0576 32.2333 45.8541 32.4286 45.5869C32.6239 45.3198 32.7937 44.9945 32.9283 44.6294C33.0629 44.2644 33.1596 43.8669 33.2129 43.4598L36.6713 17.1953C36.7793 16.3745 36.7046 15.5639 36.4637 14.9416C36.2229 14.3194 35.8355 13.9365 35.3867 13.8771L35.3849 13.8724Z"
      fill="#151E26"
    />
  </svg>
);
