import { FC } from "react";

type Props = {
  style?: any;
  onClick?: () => void
};

export const CloseIcon: FC<Props> = ({ style, onClick }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick}
  >
    <path
      d="M1.9941 0.90625C1.7382 0.90625 1.4706 0.992256 1.2753 1.18726C0.884797 1.57826 0.884797 2.23424 1.2753 2.62524L7.5566 8.90625L1.2753 15.1873C0.884797 15.5783 0.884797 16.2342 1.2753 16.6252C1.6659 17.0152 2.3223 17.0152 2.7129 16.6252L8.9941 10.3443L15.2753 16.6252C15.6659 17.0152 16.3223 17.0152 16.7129 16.6252C17.1034 16.2342 17.1034 15.5783 16.7129 15.1873L10.4316 8.90625L16.7129 2.62524C17.1034 2.23424 17.1034 1.57826 16.7129 1.18726C16.5176 0.992256 16.2499 0.90625 15.9941 0.90625C15.7382 0.90625 15.4707 0.992256 15.2753 1.18726L8.9941 7.46825L2.7129 1.18726C2.5176 0.992256 2.25 0.90625 1.9941 0.90625Z"
      fill="#CBA046"
    />
  </svg>
);
