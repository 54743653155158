import styled from 'styled-components';

export const ContentWrapper = styled.div`
  height: 100%;
  max-width: 65%;

  @media (min-width: 768px) {
    max-width: 80%;
  }
`;

export const Text = styled.div`
  font-family: MontserratarmLight;
  padding-top: 21px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Autor = styled.div`
  font-family: MontserratarmMedium;
  font-weight: 500;
  margin-top: 24px;
  padding-bottom: 22px;
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;
