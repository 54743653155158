import {
  GoalWrapper,
  GoalTitle,
  GoalText,
} from './NetworkSection.styled';

const OPTIONS = [
  { header: 'Integrated Defence', text: 'Our goal is creation of integrated digital battlefield' },
  { header: 'Network-centric warfare', text: 'We bring future warfare to existing Armed Forces' },
];

export const Goal = ({ index = 0 }) => {
  const { header, text } = OPTIONS[index];
  return (
    <GoalWrapper>
      <GoalTitle>{header}</GoalTitle>
      <GoalText>{text}</GoalText>
    </GoalWrapper>
  );
};
