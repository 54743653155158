import { Box, Card, List, ListItem, ListItemText, CardContent, Typography } from '@mui/material';
import { Header, Divider, MediaBox, SubHeader } from './ProductItem.styled';
import { OPTIONS } from './constants';
import { DotIcon } from '../Icons/DotIcon';

export const ProductItem: React.FC<{ index?: number; width?: number }> = ({
  index = 0,
  width = 0,
}) => {
  const { styles, media, header, listItems, id } = OPTIONS[index];

  const renderListSection = () => (
    <List sx={{ width: '100%', maxWidth: '780px' }}>
      {listItems.map(({ subheader, description }) => (
        <ListItem key={subheader}>
          <ListItemText
            primary={
              <SubHeader
                variant={width < 1100 ? 'h6' : 'h5'}
                sx={{ display: 'flex', fontWeight: '500', fontFamily: 'MontserratarmMedium', lineHeight: width > 1100 ? '24px' : '20px', }}
              >
                <div style={{ marginRight: '12px', width: '20px', height: '20px' }}>
                  <DotIcon style={{ width: width < 768 ? '12px' : '20px', height: width < 768 ? '12px' : '20px' }} />
                </div>
                {subheader}
              </SubHeader>
            }
            secondary={
              <Typography
                variant={width < 1100 ? 'subtitle1' : 'h6'}
                sx={{ fontWeight: '400', fontFamily: 'MontserratarmRegular', lineHeight: width > 1100 ? '24px' : '19px', }}
              >
                {description}
              </Typography>}
          />
        </ListItem>
      ))}
    </List>
  );

  const renderFirstSection = () => {
    if (width < 768 && !index) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} id={id}>
          <CardContent sx={{ flex: '1 0 auto', padding: '20px 0px' }}>
            {renderListSection()}
          </CardContent>
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: width > 1100 ? '56%' : '100%' }} id={id}>
        <CardContent sx={{ flex: '1 0 auto', padding: width < 768 ? '20px 0' : '0' }}>
          <Header sx={{ fontWeight: '500', fontFamily: 'MontserratarmMedium', fontSize: '32px' }}>
            {header}
          </Header>
          <Divider variant='determinate' value={50} sx={{ maxWidth: '560px', width: '80%' }} />
          {renderListSection()}
        </CardContent>
      </Box>
    );
  };

  const renderSecondSection = () => {
    if (width < 768 && !index) {
      return (
        <MediaBox>
          <Header sx={{ fontWeight: '500', fontFamily: 'MontserratarmMedium', fontSize: '32px' }}>
            {header}
          </Header>
          <Divider variant='determinate' value={50} style={{ marginBottom: '30px' }} />
          {media}
        </MediaBox>
      );
    }
    return <MediaBox index={index}>{media}</MediaBox>;
  };

  return (
    <Card
      sx={{
        display: 'flex',
        border: 'none',
        background: '#F4F4F4',
        boxShadow: 'none',
        marginTop: width < 768 ? '0' : '112px',
        justifyContent: 'space-between',
        ...styles,
        ...(width < 768
          ? index
            ? { flexDirection: 'column' }
            : { flexDirection: 'column-reverse' }
          : {}),
      }}
    >
      {renderFirstSection()}
      {renderSecondSection()}
    </Card>
  );
};
