import { ELEMENT_IDS } from '../../helpers/constants';
import { scrollToElement } from '../../helpers/helper';
import { HeaderBar } from './HeaderBar';
import {
  HeroWrapper,
  HeroContentWrapper,
  HeroContentTitle,
  HeroContentDescription,
  HeroContentButton,
  VideoContainer,
  Overlay,
  HeroContentButtonWrapper,
} from './Hero.styled';

export const Hero: React.FC<{ width?: number }> = ({ width = 0 }) => {

  return (
    <VideoContainer>
      <Overlay>
          <HeroWrapper>
            <HeaderBar width={width} />
            <HeroContentWrapper>
              <HeroContentTitle>Defense System Integrator</HeroContentTitle>
              <HeroContentDescription>
                We provide modern C4ISR solutions for Armed Forces and integrate
                weapon systems of all classes with our state-of-the-art
                software.
              </HeroContentDescription>
              <HeroContentButtonWrapper>
                <HeroContentButton onClick={() => scrollToElement(ELEMENT_IDS.PRODUCT_TITLE)}>
                  OUR PRODUCTS
                </HeroContentButton>
              </HeroContentButtonWrapper>
            </HeroContentWrapper>
          </HeroWrapper>
      </Overlay>
    </VideoContainer>
  );
};
