import { FC } from 'react';

type Props = {
  style?: any;
};

export const Vector: FC<Props> = ({ style }) => (
  <svg
    width="56"
    height="40"
    viewBox="0 0 56 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M45.5 10L35.5 20H43C43 28.275 36.275 35 28 35C25.5574 35.0083 23.1513 34.4068 21 33.25L17.35 36.9C20.5327 38.9256 24.2274 40.001 28 40C39.05 40 48 31.05 48 20H55.5L45.5 10ZM13 20C13 11.725 19.725 5 28 5C30.525 5 32.925 5.625 35 6.75L38.65 3.1C35.4673 1.07438 31.7726 -0.00104904 28 7.67859e-07C16.95 7.67859e-07 8 8.95 8 20H0.5L10.5 30L20.5 20H13Z"
      fill="#151E26"
    />
  </svg>
);
