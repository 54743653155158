import { FC } from "react";

type Props = {
  style?: any;
};

export const TraceryRight: FC<Props> = ({ style }) => (
  <svg
    width="286"
    height="436"
    viewBox="0 0 286 436"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g opacity="0.2">
      <path
        d="M284.578 183.281H239.739L166.918 110.459L165.772 111.605L239.085 184.917H284.578V183.281Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M164.297 113.238C163.151 112.093 163.151 110.129 164.297 108.82C165.442 107.675 167.406 107.675 168.715 108.82C169.861 109.966 169.861 111.929 168.715 113.238C167.406 114.548 165.442 114.548 164.297 113.238Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.578 240.228H269.85L174.118 144.66L172.972 145.806L269.196 241.865H284.578V240.228Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M175.917 143.189C174.772 142.044 172.808 142.044 171.499 143.189C170.353 144.335 170.353 146.298 171.499 147.608C172.644 148.753 174.608 148.753 175.917 147.608C177.063 146.298 177.063 144.335 175.917 143.189Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M285.483 288.48H241.627L142 184L140.854 185.146L240.972 290.116H285.483V288.48Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M143.278 181.859C142.132 180.714 140.168 180.714 138.859 181.859C137.714 183.005 137.714 184.968 138.859 186.278C140.005 187.423 141.968 187.423 143.278 186.278C144.423 184.968 144.423 183.005 143.278 181.859Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.576 396.515H275.576L123.222 244.326L122.077 245.472L274.757 398.152H284.576V396.515Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M124.858 242.851C123.712 241.706 121.749 241.706 120.44 242.851C119.294 243.997 119.294 245.961 120.44 247.27C121.585 248.415 123.549 248.415 124.858 247.27C126.167 245.961 126.167 243.997 124.858 242.851Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.579 261.994H240.722L183.447 204.719L182.301 205.864L240.068 263.631H284.579V261.994Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M185.246 203.246C184.101 202.1 182.137 202.1 180.828 203.246C179.682 204.391 179.682 206.355 180.828 207.664C181.973 208.81 183.937 208.81 185.246 207.664C186.392 206.355 186.392 204.391 185.246 203.246Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M284.579 202.592V200.955H268.706V202.592H284.579Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M266.721 203.988C267.936 205.202 269.904 205.202 271.118 203.988C272.333 202.774 272.333 200.805 271.119 199.591C269.904 198.377 267.936 198.377 266.721 199.591C265.507 200.805 265.507 202.774 266.721 203.988Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M173.953 263.954L175.262 262.809L256.757 344.467V378.014H255.12V345.122L173.953 263.954Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M176.902 261.338C175.756 260.192 173.792 260.192 172.483 261.338C171.338 262.483 171.338 264.447 172.483 265.756C173.629 266.902 175.592 266.902 176.902 265.756C178.047 264.447 178.047 262.483 176.902 261.338Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M107.493 177.478L108.639 176.332L170.987 238.844H193.57V240.481H170.333L107.493 177.478Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M110.278 174.859C109.132 173.714 107.168 173.714 105.859 174.859C104.714 176.005 104.714 177.968 105.859 179.278C107.005 180.423 108.968 180.423 110.278 179.278C111.423 177.968 111.423 176.005 110.278 174.859Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M133.697 99.498H135.333V127.972L226.811 219.449V248.414H225.174V220.268L133.697 128.79V99.498Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M137.459 99.496C137.459 97.8596 136.15 96.3867 134.35 96.3867C132.713 96.3867 131.24 97.6959 131.24 99.496C131.24 101.132 132.55 102.605 134.35 102.605C136.15 102.605 137.459 101.296 137.459 99.496Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.578 417.455V415.818H275.578V417.455H284.578Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M275.575 413.531C273.939 413.531 272.466 414.84 272.466 416.64C272.466 418.277 273.775 419.75 275.575 419.75C277.212 419.75 278.685 418.441 278.685 416.64C278.685 415.004 277.375 413.531 275.575 413.531Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.578 434.147H258.886L199.483 374.744V347.088L99.3323 246.938V227.955H97.6958V247.592L197.846 347.743L197.846 375.399L258.231 435.783H284.578V434.147Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M101.625 228.123C101.625 226.486 100.316 225.014 98.5158 225.014C96.8793 225.014 95.4065 226.323 95.4065 228.123C95.4065 229.759 96.7157 231.232 98.5158 231.232C100.316 231.232 101.625 229.759 101.625 228.123Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M284.576 340.05L268.703 324.177V312.395H267.066V324.832L284.576 342.341V340.05Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M270.993 312.56C270.993 310.924 269.684 309.451 267.884 309.451C266.084 309.451 264.775 310.76 264.775 312.56C264.775 314.197 266.084 315.67 267.884 315.67C269.684 315.67 270.993 314.36 270.993 312.56Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M212.739 363.288L213.885 362.143L243.341 391.435V418.763H241.704V392.089L212.739 363.288Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M215.522 360.674C214.377 359.528 212.413 359.528 211.104 360.674C209.958 361.819 209.958 363.783 211.104 365.092C212.249 366.238 214.213 366.238 215.522 365.092C216.831 363.783 216.831 361.819 215.522 360.674Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M284.578 223.211H270.014L244.812 198.174L243.667 199.319L269.359 224.848H284.578V223.211Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M246.613 196.703C245.467 195.557 243.503 195.557 242.194 196.703C241.049 197.848 241.049 199.812 242.194 201.121C243.34 202.267 245.303 202.267 246.613 201.121C247.758 199.812 247.758 198.012 246.613 196.703Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M193.429 265.593L194.575 264.447L231.231 301.104V318.777H229.595V301.758L193.429 265.593Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M196.21 262.976C195.064 261.831 193.1 261.831 191.791 262.976C190.646 264.122 190.646 266.086 191.791 267.395C192.937 268.54 194.9 268.54 196.21 267.395C197.519 266.086 197.519 264.122 196.21 262.976Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.579 115.205L192.938 23.5645V2.94531L191.302 2.94531V24.2191L284.579 117.496V115.205Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M195.228 3.10926C195.228 1.47281 193.918 0 192.118 0C190.482 0 189.009 1.30917 189.009 3.10926C189.009 4.7457 190.318 6.21849 192.118 6.21849C193.918 6.05484 195.228 4.7457 195.228 3.10926Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.576 137.623V135.986H248.411V137.623H284.576Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M248.575 133.857C246.939 133.857 245.466 135.167 245.466 136.967C245.466 138.603 246.775 140.076 248.575 140.076C250.375 140.076 251.685 138.767 251.685 136.967C251.521 135.167 250.212 133.857 248.575 133.857Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M284.575 167.242L271.811 154.478H240.391L209.299 123.549L208.153 124.694L239.737 156.114H271.156L284.575 169.533V167.242Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M211.098 122.076C209.952 120.93 207.989 120.93 206.679 122.076C205.534 123.221 205.534 125.185 206.679 126.494C207.825 127.64 209.789 127.64 211.098 126.494C212.243 125.185 212.243 123.221 211.098 122.076Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M201.938 71.835L203.083 70.6895L222.23 89.8358H259.05V91.4723H221.575L201.938 71.835Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M204.717 69.2205C203.572 68.075 201.608 68.075 200.299 69.2205C199.153 70.366 199.153 72.3297 200.299 73.6389C201.444 74.7844 203.408 74.7844 204.717 73.6389C205.863 72.3297 205.863 70.366 204.717 69.2205Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M2.94348 231.722V230.086H38.1271L132.059 323.854H175.916V325.491H131.241L37.4725 231.722H2.94348Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M0.000267506 230.9C0.000267506 232.617 1.39232 234.009 3.10951 234.009C4.8267 234.009 6.21875 232.617 6.21875 230.9C6.21875 229.183 4.8267 227.791 3.10951 227.791C1.39232 227.791 0.000267506 229.183 0.000267506 230.9Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
    </g>
  </svg>
);
