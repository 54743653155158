import { FC } from 'react';

type Props = {
    style?: any;
};

export const BooIcon: FC<Props> = ({ style }) => (
  <svg
    width="468"
    height="427"
    viewBox="0 0 468 427"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M0.932617 425.527V334.971H18.727C22.1333 334.858 25.524 335.504 28.6828 336.868C31.2719 337.997 33.5879 339.736 35.4577 341.954C37.2046 344.066 38.4971 346.559 39.2444 349.26C40.0032 352.055 40.3857 354.952 40.3805 357.862V361.164C40.4067 363.264 40.2291 365.361 39.8503 367.421C39.5299 368.983 38.9804 370.482 38.222 371.861C36.6853 374.441 34.5512 376.555 32.0254 378.001C34.7561 379.272 36.9903 381.511 38.3494 384.338C39.8632 387.938 40.5636 391.866 40.3942 395.806V400.892C40.8106 407.529 38.8634 414.089 34.9413 419.262C31.306 423.433 25.5134 425.519 17.5635 425.519L0.932617 425.527ZM13.1226 347.08V372.071H18.61C20.0862 372.288 21.5898 372.122 22.9932 371.588C24.3967 371.054 25.6587 370.167 26.6724 369.003C28.2332 366.131 28.9525 362.828 28.7378 359.517C28.9681 356.268 28.1535 353.032 26.4279 350.343C25.3206 349.199 23.9894 348.33 22.53 347.8C21.0706 347.27 19.5191 347.09 17.9869 347.275L13.1226 347.08ZM13.1226 383.41V412.481H18.3862C20.3765 412.575 22.3602 412.175 24.18 411.31C25.497 410.597 26.5895 409.488 27.323 408.121C28.0932 406.63 28.5457 404.976 28.6484 403.277C28.6484 401.42 28.8756 399.241 28.8756 397.182C28.8924 395.185 28.7773 393.188 28.5313 391.208C28.3361 389.678 27.8462 388.207 27.0923 386.889C26.2872 385.693 25.1668 384.78 23.8736 384.265C22.1438 383.639 20.3221 383.352 18.4964 383.417L13.1226 383.41Z"
      fill="#CBA046"
    />
    <path
      d="M74.9784 356.314C74.9049 352.998 75.5123 349.705 76.7582 346.666C77.876 343.999 79.5006 341.607 81.5295 339.643C83.4486 337.881 85.6568 336.512 88.0427 335.606C92.7882 333.773 97.9864 333.773 102.732 335.606C105.145 336.528 107.388 337.894 109.359 339.643C111.353 341.613 112.939 344.005 114.017 346.666C115.262 349.705 115.87 352.998 115.796 356.314V404.199C115.897 407.569 115.289 410.921 114.017 414.008C112.946 416.591 111.356 418.893 109.359 420.749C107.417 422.589 105.169 424.027 102.732 424.988C97.9864 426.82 92.7882 426.82 88.0427 424.988C85.632 424.043 83.419 422.603 81.5295 420.749C79.4981 418.899 77.8698 416.598 76.7582 414.008C75.4865 410.921 74.8784 407.569 74.9784 404.199V356.314ZM87.2441 404.199C87.1423 405.479 87.3105 406.766 87.7365 407.968C88.1624 409.169 88.8351 410.254 89.7055 411.142C91.3343 412.58 93.3827 413.366 95.4975 413.366C97.6123 413.366 99.6607 412.58 101.29 411.142C102.16 410.254 102.833 409.169 103.259 407.968C103.685 406.766 103.853 405.479 103.751 404.199V356.314C103.85 355.04 103.68 353.759 103.254 352.565C102.828 351.371 102.157 350.293 101.29 349.411C99.6701 347.952 97.6181 347.151 95.4975 347.151C93.3769 347.151 91.3249 347.952 89.7055 349.411C88.8379 350.293 88.1666 351.371 87.7407 352.565C87.3147 353.759 87.1451 355.04 87.2441 356.314V404.199Z"
      fill="#CBA046"
    />
    <path
      d="M149.712 356.314C149.657 353 150.264 349.712 151.492 346.667C152.613 343.987 154.256 341.593 156.312 339.643C158.219 337.865 160.429 336.494 162.822 335.606C166.398 334.253 170.242 333.914 173.979 334.622C177.715 335.329 181.214 337.06 184.134 339.643C186.104 341.636 187.687 344.022 188.792 346.667C190.02 349.712 190.627 353 190.572 356.314V404.196C190.654 407.566 190.047 410.914 188.792 414.008C187.699 416.578 186.112 418.874 184.134 420.75C182.185 422.6 179.923 424.038 177.47 424.988C172.737 426.817 167.551 426.817 162.818 424.988C160.401 424.061 158.186 422.619 156.308 420.75C154.295 418.9 152.691 416.598 151.613 414.008C150.358 410.914 149.751 407.566 149.833 404.196L149.712 356.314ZM161.902 404.196C161.792 405.476 161.956 406.767 162.382 407.97C162.809 409.173 163.486 410.256 164.364 411.139C165.972 412.523 167.977 413.278 170.044 413.278C172.111 413.278 174.116 412.523 175.724 411.139C176.602 410.257 177.279 409.173 177.706 407.97C178.132 406.767 178.296 405.476 178.186 404.196V356.314C178.293 355.04 178.127 353.757 177.701 352.561C177.274 351.365 176.598 350.288 175.724 349.412C174.125 348.007 172.117 347.239 170.044 347.239C167.971 347.239 165.963 348.007 164.364 349.412C163.49 350.288 162.814 351.365 162.387 352.561C161.961 353.757 161.795 355.04 161.902 356.314V404.196Z"
      fill="#CBA046"
    />
    <path
      d="M231.733 425.6V347.158H217.649V334.964H258.006V347.158H243.923V425.604L231.733 425.6Z"
      fill="#CBA046"
    />
    <path
      d="M287.284 425.604V334.964H323.551V347.158H299.436V373.804H320.484V385.998H299.436V412.643H323.551V425.604H287.284Z"
      fill="#CBA046"
    />
    <path
      d="M393.9 399.771V405.338C393.928 408.108 393.425 410.855 392.423 413.411C391.435 415.891 390.035 418.161 388.292 420.112C386.538 422.054 384.459 423.628 382.161 424.754C379.779 425.889 377.197 426.468 374.588 426.45C372.209 426.447 369.842 426.108 367.548 425.441C365.183 424.776 362.985 423.563 361.11 421.888C359.126 420.064 357.515 417.824 356.377 415.309C355.06 412.079 354.439 408.575 354.559 405.056V355.064C354.527 352.236 355.002 349.427 355.96 346.789C356.886 344.313 358.278 342.065 360.05 340.184C361.837 338.287 363.961 336.79 366.298 335.78C368.864 334.702 371.602 334.167 374.36 334.206C376.953 334.093 379.538 334.567 381.946 335.597C384.354 336.626 386.53 338.188 388.33 340.18C390.119 342.185 391.522 344.544 392.461 347.123C393.466 349.905 393.967 352.865 393.938 355.846V360.936H381.786V356.616C381.831 354.23 381.11 351.9 379.741 350.011C379.105 349.106 378.274 348.377 377.318 347.885C376.363 347.394 375.311 347.154 374.25 347.185C373.084 347.02 371.898 347.213 370.831 347.742C369.764 348.271 368.861 349.115 368.229 350.172C367.14 352.528 366.619 355.137 366.714 357.761V404.109C366.634 406.434 367.201 408.732 368.343 410.714C369.005 411.66 369.889 412.404 370.906 412.872C371.923 413.339 373.037 413.514 374.137 413.379C375.012 413.377 375.881 413.227 376.712 412.934C377.642 412.633 378.505 412.134 379.249 411.467C380.033 410.686 380.652 409.736 381.066 408.681C381.569 407.25 381.814 405.729 381.786 404.201V399.76L393.9 399.771Z"
      fill="#CBA046"
    />
    <path
      d="M427.816 425.602V334.962H440.006V373.802H455.036V334.966H467.226V425.606H455.036V385.24H440.006V425.606L427.816 425.602Z"
      fill="#CBA046"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M76.6582 158.134L149.492 200.133V208.342L158.562 213.564V194.937L76.6582 147.69V158.134Z"
      fill="#202E3A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M185.246 175.653L55.1548 100.592V111.035L176.177 180.875V208.44L163.288 256.539H172.659L172.835 255.887L185.246 209.619V175.653Z"
      fill="#202E3A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M300.261 29.6741V19.2559L265.716 39.1635C256.315 32.9745 245.305 29.6758 234.047 29.6758C222.789 29.6758 211.779 32.9745 202.378 39.1635L167.833 19.2559V29.6741L234.034 67.7823L300.261 29.6741ZM234.034 38.7367C241.917 38.7458 249.679 40.6761 256.646 44.3601L234.034 57.4394L211.423 44.3601C218.39 40.6751 226.152 38.7448 234.034 38.7367Z"
      fill="#202E3A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M238.578 195.512V179.972V142.316L467.206 10.4434V0L291.916 101.095V87.5133C291.931 78.8173 289.964 70.2321 286.163 62.4091L325.959 39.4387V29.0456L234.031 82.0908L142.128 29.0456V39.489L181.824 62.4593C178.023 70.2823 176.055 78.8675 176.07 87.5636V101.145L0.931641 0V10.4183L229.559 142.291V251.394L206.947 228.8V153.487L28.0403 50.2085V60.7522L197.727 158.659V227.52L184.336 277.552H193.707L204.108 238.791L217.751 252.423L205.365 298.565H214.736L225.137 259.804L234.056 268.741L243 259.804L253.402 298.565H262.773L250.387 252.423L264.029 238.791L274.43 277.552H283.801L270.385 227.52V158.634L440.072 60.7271V50.2085L261.29 153.412V228.725L238.679 251.318V195.512H238.578ZM234.031 84.3L237.096 88.7434L234.157 93.1869L231.242 88.7434L234.031 84.3ZM185.24 106.316V87.5133C185.229 80.4258 186.782 73.4229 189.788 67.0032L222.298 85.7309L220.313 88.7434L234.031 109.605L247.774 88.7434L245.789 85.7309L278.299 67.0032C281.305 73.4229 282.858 80.4258 282.847 87.5133V106.316L234.031 134.483L185.24 106.316Z"
      fill="#202E3A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M309.523 213.564L318.593 208.342V200.133L391.427 158.134V147.69L309.523 194.937V213.564Z"
      fill="#202E3A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M282.851 209.619L295.262 255.887L295.438 256.539H304.809L291.92 208.44V180.875L412.942 111.035V100.592L282.851 175.653V209.619Z"
      fill="#202E3A"
    />
  </svg>
);
