import { Box } from '@mui/material';
import { BooIcon } from '../Icons/BooIcon';
import { InfoLines } from '../Icons/InfoLines';
import { MarkIcon } from '../Icons/MarkIcon';
import { BootomSection, Divider, InfoMail, InfoText, MailWrapper } from './Info.styled';

const rootBoxStyles = {
  background: 'white',
  position: 'relative',
  zIndex: 1,
  borderRadius: '12px',
};

const boxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '80px 0',
};

const iconStyles = {
  position: 'absolute',
  left: 0,
  width: '20%',
  height: '40%',
};

export const Info: React.FC<{ width?: number }> = ({ width = 0 }) => {
  const size = width < 768 ? '16px' : width < 1024 ? '22px' : '30px';
  return (
    <Box sx={{...rootBoxStyles, marginTop: width < 768 ? '60px' : '140px' }}>
      <InfoLines style={iconStyles} />
      <Box sx={boxStyles}>
        <BooIcon style={{ width: '30%', height: '30%' }} />
        <InfoText>SYSTEM INTEGRATOR</InfoText>
      </Box>
      <BootomSection>
        <Divider />
        <MailWrapper>
          <MarkIcon style={{ height: size, width: size, marginLeft: '40px' }} />
          <InfoMail>info@bootech.am</InfoMail>
        </MailWrapper>
      </BootomSection>
    </Box>
  );
};
