import { CardMedia } from '@mui/material';
import { MultiMedia } from './MultiMedia';

const DEFAULT_OPTIONS = {
  header: 'C4ISR',
  styles: {},
  bigHeader: true,
};

export const OPTIONS = [
  {
    ...DEFAULT_OPTIONS,
    id: 'C4ISR-product',
    isMultyMedia: false,
    bigHeader: false,
    media: (
      <CardMedia
        component='img'
        image='cardImageFill.png?fit=crop&auto=format'
        alt=''
        sx={{ borderRadius: '8px' }}
      />
    ),
    listItems: [
      {
        subheader: 'Further integration',
        description:
          'Separate C2 systems for different Armed Forces branches can be untied together into a single C4ISR system, providing near-real time control over operations on any scale - up to General Staff of Armed Forces',
      },
      {
        subheader: 'Flexibility',
        description:
          'We are ready to build systems according to customer needs and the existing inventory of weapon systems',
      },
      {
        subheader: 'AI-driven solutions',
        description:
          'Artificial Intelligence module provides advise on optimal decision-making for officers using our products',
      },
      {
        subheader: 'Multilayered communication',
        description:
          'We use most of existing types of communication for creating stable C2 networks',
      },
    ],
  },
  {
    ...DEFAULT_OPTIONS,
    id: 'other-products',
    media: <MultiMedia />,
    header: 'Other Products',
    styles: { flexDirection: 'row-reverse' },
    listItems: [
      {
        subheader: 'Remote Controlled Detonators and Smoke Dischargers',
        description:
          'Bootech produces multi-propose detonators and smoke dischargers which are controlled remotely via tablet software',
      },
      {
        subheader: 'Training and Simulation',
        description:
          'Bootech develops various virtual reality simulators for different types of weapons, as well as combat in general. They are integrated into joint digital training combat space and can be merged with our C2/C4ISR products',
      },
    ],
  },
];

export const ITEM_DATA = [
  {
    img: 'cardImage1.png',
    title: 'Picture1',
    rows: 4,
  },
  {
    img: 'cardImage2.png',
    title: 'Picture2',
  },
  {
    img: 'cardImage3.png',
    title: 'Picture3',
  },
  {
    img: 'cardImage4.png',
    title: 'Picture4',
    cols: 4,
  },
];
