import { FC } from "react";

type Props = {
  style?: any;
};

export const DotIcon: FC<Props> = ({ style }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="10" cy="10.1934" r="10" fill="#CBA046" />
  </svg>
);
