// import { Typography } from '@mui/material';
import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography, Card, Box } from '@mui/material';

export const CustomCard = styled(Card)`
  display: flex;
  padding: 20px;
  border: none;
  background: #f4f4f4;
  align-items: flex-start !important;
  boxshadow: none;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
    align-self: center;
  }
`;

export const Title = styled.div`
  font-family: MontserratarmMedium;
  text-align: start;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 36px;
  };

  @media (min-width: 1100px) {
    font-size: 48px;
  };
`;

export const Text = styled.div`
  font-family: MontserratarmRegular;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 1100px) {
    font-size: 32px;
  }
`;

export const Span = styled.span`
  color: #cba046;
`;

export const Divider = styled(LinearProgress)`
  border-radius: 5px;
  background-color: #cba046 !important;

  .MuiLinearProgress-bar {
    background-color: #151e26;
  }
`;

export const GoalsBox = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
  position: relative;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

export const ImageStyled = styled.img`
  width: 20px;
  margin-right: 12px;
`;

export const SubHeader = styled(Typography)`
  margin-bottom: 14px !important;
`;

export const Header = styled(Typography)`
  margin-bottom: 14px !important;
  align-items: start;
  display: flex;
`;

export const GoalWrapper = styled.div`
  width: 70%;
  padding: 20px 30px;
  background-color: #151e26;
  border-radius: 8px;
`;

export const GoalTitle = styled.h1`
  font-family: MontserratarmMedium;
  font-weight: 500;
  color: #cba046;
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const GoalText = styled.h2`
  font-family: MontserratarmRegular;
  font-weight: 400;
  color: white;
  font-size: 16px;
`;

export const OurGoalContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    position: absolute;
    align-items: flex-end;
    top: -100px;
    left: -70px;
  }
`;

export const OurGoalText = styled.div`
  font-family: Rock Salt, cursive;
  font-size: 32px;
  font-wight: 400;
  transform: rotate(-9deg);
`;
