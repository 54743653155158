import { Box } from '@mui/material';
import { LeftTracery } from '../Icons/LeftTracery';
import { RightTracery } from '../Icons/RightTracery';
import { Text, Autor, ContentWrapper } from './Ceo.styled';

const boxStyles = {
  margin: '60px 0',
  background: 'white',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '8px',
};

const iconStyles = {
  position: 'absolute',
  height: '100%',
  maxWidth: '20%',
};

export const CeoSection = () => {
  return (
    <Box sx={boxStyles}>
      <LeftTracery style={{ ...iconStyles, left: 0 }} />
      <RightTracery style={{ ...iconStyles, right: 0 }} />
      <ContentWrapper>
        <Text>
          "We integrate both modern and legacy armament into modern
          network-based Command & Control systems."
        </Text>
        <Autor>― CEO of Bootech</Autor>
      </ContentWrapper>
    </Box>
  );
};
