import { Vector } from '../Icons/Vector';
import {
  Span,
  Divider,
  GoalsBox,
  Title,
  Text,
  CustomCard,
  CustomBox,
  OurGoalContainer,
  OurGoalText,
} from './NetworkSection.styled';
import { Goal } from './Goal';
import { ELEMENT_IDS } from '../../helpers/constants';
import { ArrowIcon } from '../Icons/ArrowIcon';

export const NetworkCentricSection : React.FC<{ width?: number }> = ({ width = 0 }) => (
  <CustomCard
    sx={{
      display: 'flex',
      paddingTop: width < 768 ? '0' : '140px',
      border: 'none',
      background: '#F4F4F4',
      alignItems: 'center',
      boxShadow: 'none',
      justifyContent: 'space-between',
    }}
    id={ELEMENT_IDS.ABOUT_US}
  >
    <CustomBox>
      <Title>
        The way to the <Span>NETWORK-CENTRIC</Span> warfare
      </Title>
      <Divider variant='determinate' value={50} style={{ width: '70%' }} />
      <Text>
        The Bootech company was established in Armenia in 2020 and since that is developing C2 and
        C4ISR products for local Armed Forces, striving to elevate their capabilities to a new
        level.
      </Text>
    </CustomBox>
    <GoalsBox>
    <OurGoalContainer>
      <OurGoalText>Our Goal</OurGoalText>
      <ArrowIcon style={{ width: '12%' }} />
    </OurGoalContainer>
      <Goal />
      <Vector style={{ margin: '20px auto' }} />
      <Goal index={1} />
    </GoalsBox>
  </CustomCard>
);
