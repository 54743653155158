import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  margin: 40px 0;
  padding: 0 20px;
  width: calc(100% - 40px);

  @media (min-width: 768px) {
    padding: 0 40px;
    width: calc(100% - 80px);
  }

  @media (min-width: 1100px) {
    padding: 0 80px;
    width: calc(100% - 160px);
  }

  @media (min-width: 1400px) {
    padding: 0 100px;
    width: calc(100% - 200px);
  }
`;

export const AppStyled = styled.div`
  text-align: center;
  height: 100vh;
  width: 100ww;
`;
